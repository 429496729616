import { GetSecretValueCommand, SecretsManagerClient } from "@aws-sdk/client-secrets-manager";
import { Environment, getEnvironment, isLocalEnvironment } from "../environment";
import { isNil } from "lodash";
import devConfig from "./dev.config";
import prodConfig from "./prod.config";
import stagingConfig from "./staging.config";
import previewConfig from "./preview.config";

interface IAWSSecrets {
  STRIPE_KEY: string;
}

const secret_name = "dev-env";

export class EnvironmentService {
  env: Environment;
  config: Record<string, any>;
  constructor() {
    this.env = getEnvironment();

    if (this.env === Environment.DEVELOPMENT) {
      this.config = devConfig;
    } else if (this.env === Environment.PRODUCTION) {
      this.config = prodConfig;
    } else if (this.env === Environment.STAGING) {
      this.config = stagingConfig;
    } else if (this.env === Environment.PREVIEW) {
      this.config = previewConfig;
    } else {
      try {
        const localConfig = require("./local.config").config;
        this.config = localConfig;
      } catch (error) {
        console.error("No local config created");
      }
    }
  }

  public isLocalEnvironment(): boolean {
    return isLocalEnvironment();
  }
}

export class ServerEnvironmentService extends EnvironmentService {
  STRIPE_KEY: string;
  PKEY: string;
  GRAPHQL_URL: string;
  GRAPHQL_KEY: string;
  HOST: string;
  COGNITO_USER_POOL: string;
  CONDOIT_ACCESS_KEY_ID: string;
  CONDOIT_SECRET_ACCESS_KEY: string;
  REPORT_GENERATION_LAMBDA: string;
  PROJECTS_ZIP_PHOTOS_LAMBDA: string;
  NEXT_PUBLIC_SENTRY_DSN: string;
  EVENT_WORKER_SENTRY_DSN: string;
  S3_BUCKET_REGION: string;
  S3_PUBLIC_BUCKET: string;
  S3_PHOTO_CACHE_BUCKET: string;
  COGNITO_CLIENT_ID: string;
  COGNITO_ISSUER: string;
  NEXTAUTH_SECRET: string;
  NEXTAUTH_URL: string;
  AUTH_DOMAIN: string;
  GOOGLE_CLIENT_ID: string;
  GOOGLE_CLIENT_SECRET: string;
  APPLE_CLIENT_ID: string;
  APPLE_CLIENT_SECRET: string;
  ZIPPED_PHOTOS_BUCKET: string;
  RESIZE_PHOTO_URL_PREFIX: string;
  TEST_DOMAIN: string;
  ZAPIER_DOMAIN: string;
  ZAPIER_COMPANY_SUBSCRIPTION: string;
  ZAPIER_USER: string;
  IMPORT_BUCKET: string;
  IMPORT_SQS_URL: string;
  USER_CHANGE_SQS_URL: string;
  PUSHER_KEY: string;
  PUSHER_SECRET: string;
  PUSHER_CLUSTER: string;
  PUSHER_APP_ID: string;
  REPORT_BUCKET_NAME: string;
  PROJECTS_ZIP_BUCKET: string;
  STRIPE_ENDPOINT_SECRET: string;
  CONNECTION_LIMIT: number;
  SYNC_CONNECTION_LIMIT: number;
  DEMO_PROJECTS_COMPANY_ID: string;
  SUPPORT_EMAIL_ADDRESS: string;
  SHARE_LINK_EXPIRATION_IN_DAYS: number;
  DATA_CHANGED_SNS_TOPIC: string;

  awsSecrets: Record<string, any>;

  constructor() {
    super();

    let domain = isNil(this.config.HOST) ? process.env.VERCEL_URL : this.config.HOST;

    // If the domain is not nil and does not include http, add it
    if (!isNil(domain) && !domain.includes("http")) {
      domain = "https://" + domain;
    }

    this.STRIPE_KEY = this.config.STRIPE_KEY || process.env.STRIPE_KEY || "";
    this.PKEY = this.config.PKEY || "";
    this.GRAPHQL_URL = process.env.GRAPHQL_URL || "";
    this.GRAPHQL_KEY = process.env.GRAPHQL_KEY || "";
    this.HOST = domain || "";
    this.COGNITO_USER_POOL = process.env.COGNITO_USER_POOL || "";
    this.CONDOIT_ACCESS_KEY_ID = process.env.CONDOIT_ACCESS_KEY_ID || "";
    this.CONDOIT_SECRET_ACCESS_KEY = process.env.CONDOIT_SECRET_ACCESS_KEY || "";
    this.EVENT_WORKER_SENTRY_DSN = this.config.EVENT_WORKER_SENTRY_DSN || "";
    this.REPORT_GENERATION_LAMBDA = this.config.REPORT_GENERATION_LAMBDA || "";
    this.PROJECTS_ZIP_PHOTOS_LAMBDA = this.config.PROJECTS_ZIP_PHOTOS_LAMBDA || "";
    this.NEXT_PUBLIC_SENTRY_DSN = this.config.NEXT_PUBLIC_SENTRY_DSN || "";
    this.S3_BUCKET_REGION = this.config.S3_BUCKET_REGION || "";
    this.S3_PUBLIC_BUCKET = this.config.S3_PUBLIC_BUCKET || "";
    this.S3_PHOTO_CACHE_BUCKET = this.config.S3_PHOTO_CACHE_BUCKET || "";
    this.COGNITO_CLIENT_ID = this.config.COGNITO_CLIENT_ID || "";
    this.COGNITO_ISSUER = this.config.COGNITO_ISSUER || "";
    this.NEXTAUTH_SECRET = this.config.NEXTAUTH_SECRET || "";
    this.NEXTAUTH_URL = this.config.NEXTAUTH_URL || "";
    this.AUTH_DOMAIN = this.config.AUTH_DOMAIN || "";
    this.GOOGLE_CLIENT_ID = this.config.GOOGLE_CLIENT_ID || "";
    this.GOOGLE_CLIENT_SECRET = this.config.GOOGLE_CLIENT_SECRET || "";
    this.APPLE_CLIENT_ID = this.config.APPLE_CLIENT_ID || "";
    this.APPLE_CLIENT_SECRET = this.config.APPLE_CLIENT_SECRET || "";
    this.ZIPPED_PHOTOS_BUCKET = this.config.ZIPPED_PHOTOS_BUCKET || "";
    this.RESIZE_PHOTO_URL_PREFIX = this.config.RESIZE_PHOTO_URL_PREFIX || "";
    this.DATA_CHANGED_SNS_TOPIC = this.config.DATA_CHANGED_SNS_TOPIC || "";
    this.TEST_DOMAIN = this.config.TEST_DOMAIN || "";
    this.ZAPIER_DOMAIN = this.config.ZAPIER_DOMAIN || "";
    this.ZAPIER_COMPANY_SUBSCRIPTION = this.config.ZAPIER_COMPANY_SUBSCRIPTION || "";
    this.ZAPIER_USER = this.config.ZAPIER_USER || "";
    this.IMPORT_BUCKET = this.config.IMPORT_BUCKET || "";
    this.IMPORT_SQS_URL = this.config.IMPORT_SQS_URL || "";
    this.PUSHER_CLUSTER = this.config.PUSHER_CLUSTER || "";
    this.PUSHER_KEY = this.config.PUSHER_KEY || "";
    this.PUSHER_SECRET = this.config.PUSHER_SECRET || "";
    this.PUSHER_APP_ID = this.config.PUSHER_APP_ID || "";
    this.STRIPE_ENDPOINT_SECRET = this.config.STRIPE_ENDPOINT_SECRET || "";
    this.REPORT_BUCKET_NAME = this.config.REPORT_BUCKET_NAME || "";
    this.PROJECTS_ZIP_BUCKET = this.config.PROJECTS_ZIP_BUCKET || "";
    this.CONNECTION_LIMIT = this.config.CONNECTION_LIMIT || 1;
    this.SYNC_CONNECTION_LIMIT = this.config.SYNC_CONNECTION_LIMIT || 30;
    this.DEMO_PROJECTS_COMPANY_ID = this.config.DEMO_PROJECTS_COMPANY_ID || "";
    this.SUPPORT_EMAIL_ADDRESS = this.config.SUPPORT_EMAIL_ADDRESS || "";
    this.SHARE_LINK_EXPIRATION_IN_DAYS = this.config.SHARE_LINK_EXPIRATION_IN_DAYS || 30;

    this.awsSecrets = {};
  }

  public async getSecrets(): Promise<IAWSSecrets> {
    const credentials = {
      accessKeyId: this.CONDOIT_ACCESS_KEY_ID,
      secretAccessKey: this.CONDOIT_SECRET_ACCESS_KEY,
    };
    const config = {
      apiVersion: "2012-08-10",
      region: "us-east-1",
      credentials: credentials,
    };

    const secretsClient = new SecretsManagerClient({ ...config });
    try {
      const response = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
        }),
      );

      if (response.SecretString === undefined) {
        throw new Error("SecretString is undefined");
      }

      const secrets = JSON.parse(response.SecretString);

      return {
        STRIPE_KEY: secrets.STRIPE_KEY,
      };
    } catch (error) {
      // For a list of exceptions thrown, see
      // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
      throw error;
    }
  }
}

export const serverEnvironment = new ServerEnvironmentService();
