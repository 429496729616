import API from "@condoit-apps/services/src/utility/API.service";
import { Systems } from "@/models";
import { Company } from "@condoit-apps/services/src/data/CompanyData.service";

export async function createSystem(system: Systems): Promise<Systems> {
  const response = await API.post(`/api/system`, {
    body: JSON.stringify(system),
  });

  if (response.success !== true) throw new Error(response.message, { cause: response.cause });

  return response.system;
}

export async function getGlobalSearch(searchParams?: string): Promise<{ systems: Systems[]; companies: Company[] }> {
  const response = await API.get<{ systems: Systems[]; companies: Company[] }>(
    `/api/search${searchParams ? `?${searchParams}` : ""}`,
  );

  if (response.success !== true) throw new Error(response.message);

  return response as unknown as { systems: Systems[]; companies: Company[] };
}

export async function getSystems(companyId: string, searchParams?: string): Promise<Systems[]> {
  const response = await API.get(`/api/company/${companyId}/projects${searchParams ? `?${searchParams}` : ""}`);

  if (response.success !== true) throw new Error(response.message);

  return response.systems as Systems[];
}

export async function updateSystem(system: Systems): Promise<Systems> {
  const response = await API.put(`/api/system/${system.id}`, {
    body: JSON.stringify(system),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.system;
}

export async function bulkUpdateSystems(systems: Systems[]): Promise<Systems[]> {
  const updatePromises = systems.map(async (item) => {
    const response = await API.put(`/api/system/${item.id}`, {
      body: JSON.stringify(item),
    });
    if (response.success !== true) throw new Error(response.message);

    return response.item;
  });

  return Promise.all(updatePromises);
}

export async function getSystemsByCompanyId(companyId?: string, searchParams?: string): Promise<Systems[]> {
  const response = await API.get(`/api/company/${companyId}/projects${searchParams ? `?${searchParams}` : ""}`);

  if (response.success !== true) throw new Error(response.message);

  return response.systems as Systems[];
}

export async function deleteSystem(systemId: string): Promise<boolean> {
  const response = await API.delete(`/api/system/${systemId}`);

  if (response.success !== true) throw new Error(response.message);

  return true;
}

export async function bulkDeleteSystems(systems: Systems[]): Promise<boolean[]> {
  const deletePromises = systems.map(async (system) => {
    const response = await API.delete(`/api/system/${system.id}`);

    if (response.success !== true) throw new Error(response.message);

    return true;
  });

  return Promise.all(deletePromises);
}

export async function getSystemUsers(systemId: string) {
  const response = await API.get(`/api/system/${systemId}/users`);

  if (response.success !== true) throw new Error(response.message);

  return response.users;
}

export async function duplicateProject(systemId: string, duplicateData: any) {
  const response = await API.post(`/api/system/${systemId}/duplicate`, {
    body: JSON.stringify(duplicateData),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.newSystem;
}

export async function createProjectShareLink(projectId: string) {
  const response = await API.post(`/api/projects/${projectId}/share`, {
    body: JSON.stringify({}),
  });

  if (response.success !== true) throw new Error(response.message, { cause: response.cause });

  return {
    url: response.url as string,
    expiresAt: response.expiresAt as number,
    formattedExpiration: response.formattedExpiration as string,
  };
}

export async function getSystemsByIds(systemIds: string[]) {
  const response = await API.post(`/api/system/list`, {
    body: JSON.stringify({ systemIds }),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.data;
}
