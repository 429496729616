import { CurrentUserContext } from "@/app/contexts/currentUserContext";
import { useContext } from "react";

type SuperAdminOnlyProps = {
  children: React.ReactNode;
};

const SuperAdminOnly = ({ children }: SuperAdminOnlyProps) => {
  const { isSuperAdmin } = useContext(CurrentUserContext);

  if (isSuperAdmin) {
    return <>{children}</>;
  }

  return null;
};

export default SuperAdminOnly;
