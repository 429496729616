export const config = {
  STRIPE_KEY:
    "sk_test_51LsWkvAohzMTOMO9Wvg3MxILfpoKglEXNL3E8YMY4q9KQ7YRqTiEZ5vE3eTjTaglTQIRww1vGtCMmSO9aRSMMDQG00MRK81EGP",
  STRIPE_KEY_FE:
    "pk_test_51LsWkvAohzMTOMO9fSJRibC5PmfFXdNj45F7aEzLyU0HcuUUnH3P5f2G3C0WfKjOCsUGCW8UmsHkDsZ4pT88FCcP00gjqkZkYn",
  PKEY: "",
  HOST: "http://localhost:3000",
  REPORT_GENERATION_LAMBDA: "ReportLambda-development",
  PROJECTS_ZIP_PHOTOS_LAMBDA: "ProjectPhotosZip-development",
  NEXT_PUBLIC_SENTRY_DSN:
    "https://8b7a51ba3c5a62a5824e35c17f2d20e5@o4505200322215936.ingest.sentry.io/4505630969692160",
  S3_BUCKET_REGION: "us-east-1",
  S3_PUBLIC_BUCKET: "condoit-customer-photos151958-sandbox",
  S3_PHOTO_CACHE_BUCKET: "condoit-photo-cache-sandbox",
  COGNITO_CLIENT_ID: "13kfcv451cqne0u904hejggmnt",
  COGNITO_ISSUER: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_bLBe26vy0",
  EVENT_WORKER_SENTRY_DSN:
    "https://a9801cd13be408e5f10beb1bee59bbdc@o4505200322215936.ingest.us.sentry.io/4507828789116928",
  NEXTAUTH_SECRET: "alsfdqweerww144a2322",
  NEXTAUTH_URL: "http://localhost:3000/api/auth",
  AUTH_DOMAIN: "https://auth-sandbox.condoit.io",
  GOOGLE_CLIENT_ID: "561756535786-o5mne04t0a76smbpc441ckj0acqpnmgg.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "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",
  APPLE_CLIENT_ID: "com.condoit.cognitosandbox",
  APPLE_CLIENT_SECRET:
    "eyJhbGciOiJFUzI1NiIsImtpZCI6IlhKOUI2SFdMRlYifQ.eyJhdWQiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiaXNzIjoiMkI4VUxQSFJaRyIsImlhdCI6MTY5NjUyNTIyMSwiZXhwIjoxNzEyMDc3MjIyLCJzdWIiOiJjb20uY29uZG9pdC5jb2duaXRvc2FuZGJveCJ9.31LgzMh12zx11TODTuOZXw0DiXngMl6FyVuLwTopGl4__2aVV317dHmrd8uP2IUQIadUYnLpTYqt_GEqrWe15g",
  ZIPPED_PHOTOS_BUCKET: "zipped-photos-development",
  RESIZE_PHOTO_URL_PREFIX: "http://localhost:3000/api/photos?key=",
  VIDEO_THUMBNAIL_URL_PREFIX: "https://5y55u732ldu26nbjjbjbodqy7u0utlpm.lambda-url.us-east-1.on.aws?key=",
  FILE_THUMBNAIL_URL_PREFIX: "https://ptjv64gl7oydclsfvio7dh6ebm0leewz.lambda-url.us-east-1.on.aws?key=",
  TEST_DOMAIN: "http://localhost:3000",
  ZAPIER_DOMAIN: "https://hooks.zapier.com/hooks/catch",
  ZAPIER_COMPANY_SUBSCRIPTION: "",
  ZAPIER_USER: "",
  IMPORT_BUCKET: "condoit-imports-development",
  IMPORT_SQS_URL: "https://sqs.us-east-1.amazonaws.com/121249430965/import-items-queue",
  DATA_CHANGED_SNS_TOPIC: "arn:aws:sns:us-east-1:121249430965:CONDOIT_DATA_CHANGED",
  PUSHER_KEY: "f47c901b640a265a1ac9",
  PUSHER_SECRET: "8563f4c527803423aad9",
  PUSHER_CLUSTER: "us2",
  PUSHER_APP_ID: "1713452",
  REPORT_BUCKET_NAME: "reports-generation-development",
  PROJECTS_ZIP_BUCKET: "zipped-photos-development",
  GOOGLE_MAPS_API_KEY: "AIzaSyD_ZOMbppQ3AluQhaRTsfcPvb_svKbWj74",
  STRIPE_ENDPOINT_SECRET: "whsec_784721ae4a22a2aa3757acee45e5e674d0c22f9d5866f1bb8b7b37abc7a68ce3",
  CONNECTION_LIMIT: 1,
  SYNC_CONNECTION_LIMIT: 1,
  DEMO_PROJECTS_COMPANY_ID: "1fb90f41-256a-44da-aed5-b3dc7a0eb73c",
  SUPPORT_EMAIL_ADDRESS: "dev@condoit.io",
  SHARE_LINK_EXPIRATION_IN_DAYS: 30,
};

export default config;
