export const config = {
  STRIPE_KEY_FE:
    "pk_live_51LsWkvAohzMTOMO9uA0j3jLAXvFSAR07mErRdxIFoNEW3RdoVBAbO1zxwbLXFO0NYU0lceNuzhfoXRYwtxH8ixxC00sndwQ9T4",
  PKEY: "77e6d9ea-d50b-4a1c-7350-2016313814f9",
  HOST: "https://app.condoit.io",
  REPORT_GENERATION_LAMBDA: "Staging-ReportLambda",
  PROJECTS_ZIP_PHOTOS_LAMBDA: "ProjectPhotosZip-production",
  NEXT_PUBLIC_SENTRY_DSN:
    "https://8b7a51ba3c5a62a5824e35c17f2d20e5@o4505200322215936.ingest.sentry.io/4505630969692160",
  S3_BUCKET_REGION: "us-east-1",
  S3_PUBLIC_BUCKET: "condoit-customer-photos80858-prod",
  S3_PHOTO_CACHE_BUCKET: "condoit-photo-cache-prod",
  COGNITO_CLIENT_ID: "2psq8hhvlmouqc3jpd2mc0akth",
  COGNITO_ISSUER: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_SANJyjh7y",
  EVENT_WORKER_SENTRY_DSN:
    "https://a9801cd13be408e5f10beb1bee59bbdc@o4505200322215936.ingest.us.sentry.io/4507828789116928",
  NEXTAUTH_SECRET: "2688aba8d68a2a142f32e703583c802b219125ab",
  NEXTAUTH_URL: "https://app.condoit.io/api/auth",
  AUTH_DOMAIN: "https://auth.condoit.io",
  GOOGLE_CLIENT_ID: "866146348486-vp6p9r1d3o2iohjdvtq3rp48i7o6bnbi.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "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",
  APPLE_CLIENT_ID: "com.condoit.mobileapp",
  APPLE_CLIENT_SECRET:
    "eyJhbGciOiJFUzI1NiIsImtpZCI6IkxLNFhXM1lVQVUifQ.eyJhdWQiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiaXNzIjoiMkI4VUxQSFJaRyIsImlhdCI6MTY5NjQ0OTg5NywiZXhwIjoxNzEyMDAxODk4LCJzdWIiOiJjb20uY29uZG9pdC5jb2duaXRvIn0.HYQlXfuycQg9NLJAKLhzDzCP1bkCC1JcEvYxRaAMKStWaKk8HU5---5Drgp43piajIh8cdMlVG8XsqadWzSmHw",
  ZIPPED_PHOTOS_BUCKET: "zipped-photos-production",
  RESIZE_PHOTO_URL_PREFIX: "https://app.condoit.io/api/photos?key=",
  VIDEO_THUMBNAIL_URL_PREFIX: "https://wwaioomwv2eg5nfcpwa4mbalre0rbrts.lambda-url.us-east-1.on.aws/?key=",
  FILE_THUMBNAIL_URL_PREFIX: "https://3arb2kjvlf3nihcrepypx7omam0qdkwe.lambda-url.us-east-1.on.aws?key=",
  TEST_DOMAIN: "https://condoitdev.com",
  ZAPIER_DOMAIN: "https://hooks.zapier.com/hooks/catch",
  ZAPIER_COMPANY_SUBSCRIPTION: "/12940704/31mvcqd/",
  ZAPIER_USER: "/12940704/3116nar/",
  IMPORT_BUCKET: "condoit-imports-production",
  IMPORT_SQS_URL: "https://sqs.us-east-1.amazonaws.com/706616837771/import-items-queue",
  PUSHER_KEY: "1c4ee24d9ac43b804bb2",
  DATA_CHANGED_SNS_TOPIC: "arn:aws:sns:us-east-1:706616837771:CONDOIT_DATA_CHANGED",
  PUSHER_SECRET: "aec59c2ce6ec39e28d0b",
  PUSHER_CLUSTER: "us2",
  PUSHER_APP_ID: "1713454",
  REPORT_BUCKET_NAME: "pdfs-generation-production",
  PROJECTS_ZIP_BUCKET: "zipped-photos-production",
  GOOGLE_MAPS_API_KEY: "AIzaSyD_ZOMbppQ3AluQhaRTsfcPvb_svKbWj74",
  STRIPE_ENDPOINT_SECRET: "whsec_rVU2tByzLbXvsF29CToPYTg0aLr8wkzS",
  CONNECTION_LIMIT: 1,
  SYNC_CONNECTION_LIMIT: 30,
  DEMO_PROJECTS_COMPANY_ID: "f6d7a426-27ed-4bfc-ba3b-3d7d80b92646",
  SUPPORT_EMAIL_ADDRESS: "support@condoit.io",
  SHARE_LINK_EXPIRATION_IN_DAYS: 30,
};

export default config;
